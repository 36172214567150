import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx";
import Helmet from 'react-helmet';
import CaseStudyIntro from '../components/CaseStudyIntro/CaseStudyIntro';
import CaseStudyConclusion from '../components/CaseStudyConclusion/CaseStudyConclusion';
import NextCaseStudy from '../components/NextCaseStudy/NextCaseStudy';
import heroImage from '../images/Heartwood-Hero@2x.png';
import shareImage from '../images/Heartwood-Thumb@2x.png';
import spruceLabsThumb from '../images/Spruce-Labs-Thumb@2x.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet meta={[{
      property: 'og:image',
      content: `https://tinyeahno.com${shareImage}`
    }, {
      name: 'twitter:image',
      content: `https://tinyeahno.com${shareImage}`
    }]} mdxType="Helmet" />
    <CaseStudyIntro title="Heartwood" subtitle="A design system to propel Spruce Labs’s mission to define the future of brick and mortar retail." heroKey="heartwood" content={[{
      kind: 'text',
      headline: 'The Brief',
      text: "Spruce Labs's mission is to help brick and mortar retailers thrive by enabling them to create great experiences. From day one, Spruce has found success by combining technology with a human-centered approach to experience management."
    }, {
      kind: 'text',
      text: "Spruce evolved for years without any real design direction. We created a small library of components early on, but it hadn't changed since. As a result, component availability became the driver of experience design. This created an experience that felt wonky and awkward."
    }, {
      kind: 'text',
      text: "My goal with Heartwood was to create a shared design language to help our team improve Spruce. I decided early on that this would be more than a component library. We needed a tool, or a set of tools, that would scale with the product."
    }]} aside={[{
      headline: 'My Role',
      items: ['Design Strategy', 'Digital Product Design', 'Visual Design', 'Frontend Development']
    }]} mdxType="CaseStudyIntro" />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGElEQVQoz61Ta2/DIAzs//+b7ZpN1ZQHhkAS7Pl4LaRfi2Rh+47DnJLbtKwyzu4jAa1bTc4xG41Lr8ML59qHVhOMkQWLmeU4Yqo5t2T1m5ANUhcwcMDlcsYo3gRxYxY6xHsvz+FHxnFKNXMUH3YhF1IeY5RpmuXxNYgxlGoE8G5C3OhDUMEgbvU6Vc73/WgTIkcPmHVryiuH7EUQnizG6+7bXuPf24wtVOLEffPwiL1vySvOtSsToo6FB8v5VL95CAAEIlJ/vuX1+pVt23FKn5g9rMso5/4YxFrXet2T8VwcTt4oCWYTWVnVyxC25mGeiMWpf8DhdX5VN2F+e/VwoZBAo3v1qfs+wdN+xc/fbBKcFief+lug8wcGslU2m76bTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c997e0d9501573013a30217ce535df77/e93cc/Design-Principles%402x.webp 300w", "/static/c997e0d9501573013a30217ce535df77/b0544/Design-Principles%402x.webp 600w", "/static/c997e0d9501573013a30217ce535df77/68fc1/Design-Principles%402x.webp 1200w", "/static/c997e0d9501573013a30217ce535df77/a2303/Design-Principles%402x.webp 1800w", "/static/c997e0d9501573013a30217ce535df77/4293a/Design-Principles%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c997e0d9501573013a30217ce535df77/aaafa/Design-Principles%402x.png 300w", "/static/c997e0d9501573013a30217ce535df77/8db2a/Design-Principles%402x.png 600w", "/static/c997e0d9501573013a30217ce535df77/6ca50/Design-Principles%402x.png 1200w", "/static/c997e0d9501573013a30217ce535df77/4d07a/Design-Principles%402x.png 1800w", "/static/c997e0d9501573013a30217ce535df77/ee4de/Design-Principles%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c997e0d9501573013a30217ce535df77/6ca50/Design-Principles%402x.png",
              "alt": "Heartwood’s core design principles are Humanity, Physicality, Augmentation, and Flexibility",
              "title": "Heartwood’s core design principles.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Heartwood’s core design principles.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Defining our Design Principles`}</h2>
    <p>{`Before getting into visual styles or components, we defined our design principles. These would serve as stimulus for all future design decisions.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.83333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACMElEQVQ4y31T7Y6bMBDk/d+wUqVKd5cjgA34CwzJdTuzxImTa/tjZWOvZ2dmlyatu7xGXDas223ld5aQ1tvZfr8r93U0fwMsoIwlY58WcS7o/rjf5F/vmvpxHTz3aRM7JXn/tPJ2MtLbIC7kp4LfAF8P68Q5rACJ8nk28n7qFHB0q/yPxJ1hSLnyDFKxkk1ngkwuyewXOXezmDFV0r/bpIA+rpCG6nMEyMGABXzMMsO7EBe5fv2Wj7dWhsFJhIch5juzmowCurAoAx5OAPUAJbgWmTweg9WadSVbRil+EAmK8QKYUGmDJA+Zsyb21kvbz3JqrXwg2m6S3ngZrNN7kvBgF0jExQcgJfNgmr0y2farDGbUhwwWCcjp+lG6YUIuGHkCZPn545d07Vnt4Tg1D0NxsKySt11B6R2Z+xBl3y8aCfM4ozDvPACZa4ZRIqyIpSl1ZxkEYegeVXvIL55R5uGbh5qAgc9qVf2+4eUjggJQIvfFqwFn3Aew6yHZhaSdp02UTtblTUOAOtrOqk/lm5KLFZfLFV0PYtRXFCPIOOu+5DfF+MKMIzBpRYcOTygw4k+xuu8GMj3U9PCOwKfW6FQUnCdAetfbo9IhN+rsOefR+Yt8IRybApkLz1G8qCkYTdlw5fi0GI0z2JAp54z+Oc4bmsFvhg4zGuVuP0CN8cSQHasT+NdwNj2akPH/BozTiByyKg0r3t0l13QZZTwe3fZHAyavd4b+3XKZZ6tv4vwBB0xkGHWnzqAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b269523d79651bf420b40d552aa3d7f1/e93cc/System-Architecture%402x.webp 300w", "/static/b269523d79651bf420b40d552aa3d7f1/b0544/System-Architecture%402x.webp 600w", "/static/b269523d79651bf420b40d552aa3d7f1/68fc1/System-Architecture%402x.webp 1200w", "/static/b269523d79651bf420b40d552aa3d7f1/a2303/System-Architecture%402x.webp 1800w", "/static/b269523d79651bf420b40d552aa3d7f1/4293a/System-Architecture%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b269523d79651bf420b40d552aa3d7f1/aaafa/System-Architecture%402x.png 300w", "/static/b269523d79651bf420b40d552aa3d7f1/8db2a/System-Architecture%402x.png 600w", "/static/b269523d79651bf420b40d552aa3d7f1/6ca50/System-Architecture%402x.png 1200w", "/static/b269523d79651bf420b40d552aa3d7f1/4d07a/System-Architecture%402x.png 1800w", "/static/b269523d79651bf420b40d552aa3d7f1/ee4de/System-Architecture%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b269523d79651bf420b40d552aa3d7f1/6ca50/System-Architecture%402x.png",
              "alt": "Heartwood’s core design principles feed into shared styles, which are used to design components that feed into features and production.",
              "title": "The Heartwood system architecture.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The Heartwood system architecture.`}</figcaption>{`
  `}</figure></p>
    <h2>{`System Architecture`}</h2>
    <p>{`The next step was to determine Heartwood's system architecture. The last thing we wanted was to create a pretty design system that never gets implemented. We worked with the engineering team to figure out how we could get Heartwood into place and keep it up to date.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAADMElEQVQ4y41US2sTYRTt73Ij6so/4kJExKUrcSG4EYpuLIoPRIQKalFQ0aqtWNQ0VWNrkmbSJNNJZjKZZN7veLz3TqapurFw+G6/x7nnnnsnC0GYwAsz+FGOOJ0iTnJECcflOhVw7NPdIEoxneYA/kUYp1iIkgw9bYidRgfNVgd7vT666oBWDR2KW3sqlM4+tIFJyTIYVogHLzUsv9EJAzxaNSR+ujaEOYmYMEd9dw+fKl9R/baNjc9b+FKtobJVw4eNChqUxLQcQZplUDQfJ89/x7Gz2zh+dgdHT1dx5NQmTpzZQkv1sRDGGdqkoLbdFIU/fu7ia60OZaast69D1Qw4foSEbNBHPu49VXB3eQPLK+t48dHG+00XmpnSnaQg5BJ36gqaSlcUsWJ9OMZwZMMYTaCbY4zGLiLySDM8XL1Tx+LNd7j38DUR+livepi4ObyAPGST9/vkYb0lKn/s7ErMez1Nl1XTTeyTh14QkU8xHq9aWK2EqDbIAjXC2OWGZdIwUdjXR+iofRjmRJTxyp5ZE1eU8cqXkyyHavi4fEfBpVttLD1R0WhbWPvcRaU2gO3FBSF3s95sC7HAKCDqSBmDSXksvCAh0oiaE2Hk5Li2tIJzF5Zw8coDqmg0V8jms5qx7dFjTwgY8j+hnEEviAkhJrYrQrZqDazTNLx68548nxQeDkhNOW+MdleTzpdrq92TpHw3IoRhjt7Qx+Z2C89freH2/UdYvH6DpkEvCFnJwLAKD6mjHLOX+tA6UDmiNeGPwPLxtvYJfu0ZzG8KfmH+xzMthPzAtGyR7HghXJq5EmxJCR6boROh2e8iUL/DVPrwaa+8y7YIIQ8kd6j06W/wfoFUSNP0F5wYCLPpobNkTmg5Ic1XQFliycTGl8pLxbznEpxZzI/ZAk7AX1CSzuaQD+SyH/4hXZTPyJiEY8bE8eU8y3Pk+VSIeM0IQlg8jGC788elAtsNZiSBgO/xSPFZSkOeEcIoPogPCA+XWiCZlewLISdjsnIOpdz0ECGpTIUwKUoWFW6pIjzwkEelVMYJuVybzvlNnKRSrk/fN8cM/rFe+LtL/4d55/+cggS/AenCi6UK3lUrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d1a3ace525d2b5a5e17cbe9ea745791c/e93cc/Shared-Styles-Alt%402x.webp 300w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/b0544/Shared-Styles-Alt%402x.webp 600w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/68fc1/Shared-Styles-Alt%402x.webp 1200w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/a2303/Shared-Styles-Alt%402x.webp 1800w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/4293a/Shared-Styles-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d1a3ace525d2b5a5e17cbe9ea745791c/aaafa/Shared-Styles-Alt%402x.png 300w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/8db2a/Shared-Styles-Alt%402x.png 600w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/6ca50/Shared-Styles-Alt%402x.png 1200w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/4d07a/Shared-Styles-Alt%402x.png 1800w", "/static/d1a3ace525d2b5a5e17cbe9ea745791c/ee4de/Shared-Styles-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d1a3ace525d2b5a5e17cbe9ea745791c/6ca50/Shared-Styles-Alt%402x.png",
              "alt": "Shared styles include text, color, layer, icon, spacing, and motion.",
              "title": "A sample of Heartwood’s shared styles.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A sample of Heartwood’s shared styles.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Shared Styles`}</h2>
    <p>{`Using the architecture as a map, the next step was to define shared visual styles. We divided our styles into six main categories: color, typography, spacing, iconography, layer, and motion.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAADXUlEQVQ4y2VV227jNhT09/YP+g19LvoPRZ/7UKBPbXc3KNoAaXpB1rvexHYS27qQIkVSlKzpnCNZ2aYCDiQdkcOZM4fUyocOjQ+MhDbmJTTvprwPGY7vkmsj4/Ks7/k/sZIBxjbIOUGuYTgj92cE5i3zQz/l5RrHswJWVY2irHAqSpR8HvntfD4jdT1WshLGAXf3DYxLBO7QdRPDYehx897i6rbG7/8YbPZeJ3nfom0D+mFA3/cKNo7jC6BMLEzUhAyQkPyfHx1+uCrx/ZsC3/54wOMposv8RrCsQOMCJnNCEsmcmHOmtKzJlMgwZ5UmsoXtJd9zjExqGo/aNKhry7tFCCSTEmKaaziQeubKiZMjJ8qHwAIPsyTJp0teAR2c83wnSEw6ZpHs2oTaelg6qk7NzkneNO3i6CUEsJsXmNgPs2HjxFAGGes42bFNWjJLU51CgqW0NkzvmRPlLsxljJaJChS8mxaJwrDxEdvHI56PFWoyqoywjer4qXLMyXMk6x7WT6Vw3mvLaFsNU/2lU2TBlUw8FA7HkoMqAtqI2mUF1IKTjbgpknIeJsmc6Ng6Ittw7LFKKK3Ud2F4wv65RFF7FMoqqOTjqaCUbmlsaREBbNPI+6i5r7/b4Yuv7vDlNx/wXEasRNKxdJSZaETWMJQmWy6EgM8vYSkLScs4Eml123aqRkL8WEm9Pu2OylCADyerTMXlU1lT3qAhLSFbsqgMfnrzDpuHJz43KlNYS5coYEMjHu422K7vYQ8VfGEZhq0UVL4sKOwDTXFxQF0a/PXzL3j/ccsFzXKQiKGuJaCntIpA1aFGfaxhygaNbac+pCkxxml7MQaaYyj39upXfPi0o4n2/4CmSdjuC+yf2AZOmrdH0/bK7On5uLgsp1DPkF5drzdk+MDuqFWy9vIFUIpfFdyXZGYrz20VZ3NoknN4fdXG4faPv8lwr727MJxV6dbbsW12TwV7sGXzJmUnectVOxqRZlO6ftQD4be377De7GjiSw1NczGFAHuCPR5KNUGcE/rSTpv7R7pe6yKyU0qbFPDu5hr3u8O01+eTWs4DZSioLqT5eM/LXRYSQInt/kBHrbotgDfX11hTsrj8UsOZ4ev/yOfP8i/R/0nbzf+W6cSRUlg/lcW/Oo3+BXrATshMtuvKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/e3e48196c4a0b9e754684980faa62be2/e93cc/Components%402x.webp 300w", "/static/e3e48196c4a0b9e754684980faa62be2/b0544/Components%402x.webp 600w", "/static/e3e48196c4a0b9e754684980faa62be2/68fc1/Components%402x.webp 1200w", "/static/e3e48196c4a0b9e754684980faa62be2/a2303/Components%402x.webp 1800w", "/static/e3e48196c4a0b9e754684980faa62be2/4293a/Components%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/e3e48196c4a0b9e754684980faa62be2/aaafa/Components%402x.png 300w", "/static/e3e48196c4a0b9e754684980faa62be2/8db2a/Components%402x.png 600w", "/static/e3e48196c4a0b9e754684980faa62be2/6ca50/Components%402x.png 1200w", "/static/e3e48196c4a0b9e754684980faa62be2/4d07a/Components%402x.png 1800w", "/static/e3e48196c4a0b9e754684980faa62be2/ee4de/Components%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e3e48196c4a0b9e754684980faa62be2/6ca50/Components%402x.png",
              "alt": "A collage of Heartwood components including calendars, inputs, lists, and modals.",
              "title": "A sampling of new Heartwood components.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A sampling of new Heartwood components.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Components`}</h2>
    <p>{`The component design phase began with the humble goal of parity. It was important to make sure we didn't forget to update any existing components. This would let us phase Heartwood into production without breaking anything. To ease the transition, we did a thorough audit of the existing codebase. From this audit, we redesigned each component to use our new shared styles.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB50lEQVQoz21Sy27TQBTNh7HhJ/gGJFbs2fNYFYlVxapFIqgSUneIh1pWRUVNFwQQJUpqx43f47fn4ViHmZs6shsWV9eee+bce86dUVlL1KIBl2sI1VI2Z3ej4orq/fgfbmSArh9hYd1gtrApm7MuuoYsKzG/dihm8yGu35QIvYDBDxOwtNRR3OmqNKFCogkdN6Aca8wGp3aaj4Rs8G3KcHrBsP8+w+tjH88PF9gbW3j7cYXTSQwv5gRmGUeUSv0vYHsCS6+G41f6jFNtO2GU6MmyGqtAYBXWmDkFruwcllviJiiRVxJ5yUl2WnCkuSCSRGdzb3N2S8ilwuR3hK+TSE+a4OxHQqRtq5eka6ZOkvMaflwgK6UmEDokfZvaQLJqGhx9cfH0YIEXbyw8O7zG+c8E63VDk3VmGxI3qhEmHD7jZEPA+O5ShFI4uQgx/uRqz1yMP3uUv/9KqXufcBVWmpQTsR8bP8W2vn02UjX4cBbg5Tsb+8cOXh0t9cQeEXRAk7NCkPHGtzgVt7KHhDRh2zYk9d7DCR48meL+o0s83vtL/vWlGM9YJkiq7VWIsw3xDqGRNVvmWiLDdJ7j8irDH6vY8SavDEE1WEheyl0PzYfUPpolNHpBJptFdYDNhW6TcvCQ+5N18Q+sZYdZHOvirwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/fa5fe5668bf9288ecff6a831b2328fb1/e93cc/Component-Example%402x.webp 300w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/b0544/Component-Example%402x.webp 600w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/68fc1/Component-Example%402x.webp 1200w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/a2303/Component-Example%402x.webp 1800w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/4293a/Component-Example%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/fa5fe5668bf9288ecff6a831b2328fb1/aaafa/Component-Example%402x.png 300w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/8db2a/Component-Example%402x.png 600w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/6ca50/Component-Example%402x.png 1200w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/4d07a/Component-Example%402x.png 1800w", "/static/fa5fe5668bf9288ecff6a831b2328fb1/ee4de/Component-Example%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fa5fe5668bf9288ecff6a831b2328fb1/6ca50/Component-Example%402x.png",
              "alt": "Primary and secondary buttons shown as documented.",
              "title": "An example of more in-depth component documentation",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`An example of more in-depth component documentation`}</figcaption>{`
  `}</figure></p>
    <p>{`The previous round of component design was lacking in detail. This caused a problem for the design team. To see a component in detail, one would have to look at production, which was often unstable. This situation meant the design team was often blocked from making progress. To solve this problem, we added in-depth details to our design documentation.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "117.41666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEHUlEQVQ4y3VUz48UVRDuv8SL/hccvMjFq5oIJsTEiYkx6kEOHNYExINeMNEEDyYaiEGF3SVsYN0FsgrZXYRdjIjLssPADLvzs2eme/rH69evf8xnVfXMOHOgk5rX8+r196q+qvqsSBu0OzYazRaarba8h6FCHBvoOEaoNKI4geOHqNabaHb70CaF5yuEUYyY3uUM4SidwGKnMQk5jBg/+XCIIVmeF+uQ9hquh3KrgyoBhpouCiO60JAfspokpTWFZZIMvh9QhG047gB9x0XH7sFxBhRtFwcUVUAfG62QKBdGDeC6rmQQRVqMA8nzXKK1+IedQRDSRgJNtys6pFREQAo+7Q/zFP8+83F+pY7FW214AQMpoYazYzDORABVZIiDSHjhCE2SYPrJh/KLvX2FKxt9XN9y6Wxe+MiZEViWFaaJayslX6PRwMXz5yQqrbWk3e+7GAw8+YgBTEzRaA+aUnYcRzIa85xLdJkUz+Kbyrs7OP7eMaSJFoK50v2+I1RwOnLm8R7KexXUmx3aj4tijYyfSYQxFaXe9rH9sAbbiYRku9sTMH64aJxF/dkTHNRq6FHkaZpOUh53Al/MtFleaHD8uyqOfvEEZy41KUqDLveaLqLgm9MMcLo2bNsWQN6bjrA4l40iJNQsG8JQGFyAaMRhkhTVS6i/yI3n1RqqtX0CdGRPohrZDCD/DId5YdJL3PExTUIAl4rCPcrP050HqDx+BLvnUKuY2cafBmxR825v3cfu9XlUb1/FP6sLuHZ5AXMnT+LtI2/h1KnPsPXXAwQE3O15MmL88aTCExtxyFW79fs6/l74Ebu/XUJldR6/fnMaH3xcwjtH30Cp9C5u/rEpaSdZwWnRf4WlBJ5O92HtoIHllRvYuTaPvZtXcbC5hqUfzuLw66/h0KuHcKT0CdbW70vaTItUnoC+/upL3NvcGLVMxt5iUspPqzhz9lvcvfA9Hi0vonxjCbevLOHE3Od46ZWX8eb7J7C8dndmemIqyuryCqqVyuw+Az6n4f/pl4vYunwB1Y017N9bx8M7f+Lcz4soffgRPp07jTvbOzJiLGcRFazbc+F4ITq0+qIBRnRAAJlIl6TJ8zxpFR72KIrk4yLNooIp+TKylPyuy82vxEISipTOJnSRpoJZrGGDwKA3iOH6ha4J0TQN47HjXgtJC0PKJmzbiKh1NPcjBzHwaSWjFotI5iwe6JAUJ1BGVh65SSVH1eNqcgb1eoOi6lIv9tFzWHXMxBIKgMfYiki2eaQ6nY6IQqvdkaYWAdWxTA5HyzLXc0O6NJXLWKpzyoSNKZkILHPoh5SuF4mwjnnjA9OmNHEdED1hUgiGzPisRePR81VxOEmnQf7XupTUQWkjU8IZMTUvMksOEigD8ztvqpGNDxX7ydT/5IX2H+j2woD1EETEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf48845204546220af67cb4bde96936d/e93cc/Cards-Alt%402x.webp 300w", "/static/bf48845204546220af67cb4bde96936d/b0544/Cards-Alt%402x.webp 600w", "/static/bf48845204546220af67cb4bde96936d/68fc1/Cards-Alt%402x.webp 1200w", "/static/bf48845204546220af67cb4bde96936d/a2303/Cards-Alt%402x.webp 1800w", "/static/bf48845204546220af67cb4bde96936d/4293a/Cards-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf48845204546220af67cb4bde96936d/aaafa/Cards-Alt%402x.png 300w", "/static/bf48845204546220af67cb4bde96936d/8db2a/Cards-Alt%402x.png 600w", "/static/bf48845204546220af67cb4bde96936d/6ca50/Cards-Alt%402x.png 1200w", "/static/bf48845204546220af67cb4bde96936d/4d07a/Cards-Alt%402x.png 1800w", "/static/bf48845204546220af67cb4bde96936d/ee4de/Cards-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bf48845204546220af67cb4bde96936d/6ca50/Cards-Alt%402x.png",
              "alt": "Seven variations of the Heartwood card component.",
              "title": "Examples of cards that tie to specific use cases.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Examples of cards that tie to specific use cases.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Cards for Humanity`}</h2>
    <p>{`We spent a week or so brainstorming about cards. We focused on what we could use them for, where they would live, and what types of content they'd need to support. Our goal was to invest in a component that would make the platform more flexible.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAADX0lEQVQ4y02U+09cVRDH909Rq2mxiUAt1IoawGqjiFmN8Z34i7/6i79pakx8xKaYKppGmwZjYkzU/qCAFsECtQvsboEU6MK+2F32dffefd19s08+zrkY7SSTe+58Z75nZs6ZY0OkVttHS+noRpqUbpDN5pSZdruNrqf/V8GVTUlGfJSvFZMyLA4ltkq1yoj9Nbp6BujpH6Snb5AjXX1MTs/w2YUvuffow/SeGhJsiHuOnhDbOL8Jdv+DffSKTfkfe+i0xVGt1rCpzJ59/lWes7/BM6OvMPSUnZOPnOHSt9/x3rmPefLsi4y+8KZl73/0ad7/4FMufTPBY4Mj2F96i2HBnxge5ezIy1bGtrxZYNMTIJ4wiMaSaFKaWSgRCu+x5fETiaYESxGNa5TKNWLxJHe2/fiCUZKaIXhC/MvWOp83sZlCqAIDu3vsRuKYxQq1/SaRvbgV6A9G2A1HSWfyNFsH0msDz46fbW+QoMQkkjr1RotszjwkVBlG47JLsUSrXoFOi0qlQigUkd1lg3JFMitRbzZoyYHEE5oQhdGFoCw9axwc0Gh3SEhlisuWy2UI/vURJfcnrE1+TurvMaqOcwTckyS9m3QCq6QcTrRFB21Za55b3Pbu4NfDbEX8zLtdeIw9HL5Ncvm8ZJhNk/z9XRoL77A6NY4x/yHV2bfxL/9MYmsVgk5y6xtkb61zEHShbSyxtu1hI+rF7dvmmmMNbyqE27vxL6HUnY55aJphCnqIupmgmosSCfkJ7YYw0zp5LUm7XJSLWSeVSEhfA5TLaTqdCs1GSW7fvvznDntYLBb4firIxLQmPWmwLw2u1BqEIzFisZg4t0Q7lCpV2gdgGAbONT9f/Bjkyq9RJiajfP1LjJ/+DKG4bIVCgdu+HL54h7vF0DXmXTEuT5tcXSz8ZzfNPDuBmJRYZ2W7IVrHudNk3ZtHcdnSmSzXZmZZWVlhbv4m1xeXmPpjDseyk6uTi1y8MsPlH+a4vuAQvcm8HI7CbtxYwOVysry8hNvtZHZ2FsVlU+MyYn+dru4Ba8R6Tw3zwPF+a/TGLn7F8e5+Tj9+hhNiv+/YSc6PjVvYka5+axy7+4Zk9AYsDmv0VBnVWu3wcZDRUd+M7KSk1WpZF1mpGiulymY9DuJzd4ziUPIP/56fCcTcGP8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32efdfcb97b8bacd9ec9b01fc229c541/e93cc/Onboarding-Card-Alt%402x.webp 300w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/b0544/Onboarding-Card-Alt%402x.webp 600w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/68fc1/Onboarding-Card-Alt%402x.webp 1200w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/a2303/Onboarding-Card-Alt%402x.webp 1800w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/4293a/Onboarding-Card-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32efdfcb97b8bacd9ec9b01fc229c541/aaafa/Onboarding-Card-Alt%402x.png 300w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/8db2a/Onboarding-Card-Alt%402x.png 600w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/6ca50/Onboarding-Card-Alt%402x.png 1200w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/4d07a/Onboarding-Card-Alt%402x.png 1800w", "/static/32efdfcb97b8bacd9ec9b01fc229c541/ee4de/Onboarding-Card-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32efdfcb97b8bacd9ec9b01fc229c541/6ca50/Onboarding-Card-Alt%402x.png",
              "alt": "The onboarding card shown in a phone.",
              "title": "Some illustrations we created to give the experience more personality.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Some illustrations we created to give the experience more personality.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Onboarding`}</h2>
    <p>{`The new card component was a perfect candidate for improving our onboarding experience. We had already considered an onboarding card as one of its use cases. The advantage of having some of the simple design decisions already made was that we were able to focus on the trickier parts of the problem.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACXUlEQVQoz31STWsUQRDdX2qikBgvgqJJCOQcBQ+CXoSAeBPEqFESEQUTg/EUgtGE3WSzmZ39yM7XzkdP90xPzzyre3bNRezlbXU3U6/fq6rG9qdd3FhYxsLtVVybu2/2M/NLmJlbNPH6TcICYX7x736W7u8srWFp9RHuLj/AvZWHmL21go9f9tBgqYDj+nC9AJxzlGUFkRWQCuCZgiwUqrLEdOl9SfD9CK0zC612F93eCJdOiJTnRMglsiyHNxpCSjlJK+EMekgSBqVqstTqgMWxeYDexGDoonlqYeiM4XgRvCA1Qhqc/pIkxUXbRsQKUlWBpRLuoE+KhSEsiCTqdZELjop+2kUQMqPO7rtw/RhuwEihRCPLpGFmGakQ2uYEJFYWNcgABEUmSsQpfcsVvHGKtuUQGUNMyT6dudCEeYFxkuH4nOOwleKgyXDc4Tgh/DxNzd1Rm2PnYIx3uy42vrrY/Obh3A5hDzyMiDAIOS49Bl2+Rk4Sgkhg/yjE7uHYJP74FWF738ebnTp567uPpiVwZmfo9HN0BtI0kcUBSpUbVCaqWqH2PvQkRkEBh+COC1iXAkEsUVUV2a7oTtHDFajcpjQvP/ex9uIEz95aWH/fw5PXNo4v2BVhyCqqBTWEU624boyg2tEE5Ar6m4TqpC3pURJ03txz8XSjj+dbQ6x/GODxKxu/O0QoMj029ejokSiK0sSUuqDJ9D6nodQTkKTKNERHKSc2izpqKFXUNZRSGTU6Ue9zAxpgsqst6zGJWElElVGfcO0mR0TNnCKMhXHSwH9XTagxPV/h3+sPUuN29PBr+GwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/42a635aae63a5f028232348813688bd6/e93cc/Calendar-Alt%402x.webp 300w", "/static/42a635aae63a5f028232348813688bd6/b0544/Calendar-Alt%402x.webp 600w", "/static/42a635aae63a5f028232348813688bd6/68fc1/Calendar-Alt%402x.webp 1200w", "/static/42a635aae63a5f028232348813688bd6/a2303/Calendar-Alt%402x.webp 1800w", "/static/42a635aae63a5f028232348813688bd6/4293a/Calendar-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/42a635aae63a5f028232348813688bd6/aaafa/Calendar-Alt%402x.png 300w", "/static/42a635aae63a5f028232348813688bd6/8db2a/Calendar-Alt%402x.png 600w", "/static/42a635aae63a5f028232348813688bd6/6ca50/Calendar-Alt%402x.png 1200w", "/static/42a635aae63a5f028232348813688bd6/4d07a/Calendar-Alt%402x.png 1800w", "/static/42a635aae63a5f028232348813688bd6/ee4de/Calendar-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/42a635aae63a5f028232348813688bd6/6ca50/Calendar-Alt%402x.png",
              "alt": "The Heartwood calendar.",
              "title": "The new calendar view turned out to be indispensable for teammates.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The new calendar view turned out to be indispensable for teammates.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Teammate Booking`}</h2>
    <p>{`The main consideration for teammate booking was to start with a calendar view. On large enough screens, we opted to keep the calendar visible. Desk staff and receptionists tend to use large screens and like to see the whole team's schedule. This view makes it easy for an employee to find an available time and provider.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACTklEQVQoz62Ry2sTURTGZ+FGN1ZLahLNO5mZZDIzmcxkkpnJO7bGlDR1o4gigo+22NSUtlp8LMS/QChto0QrFXEhuPMB+rd9PfcGRCN05cDhzrmc8zvf+a5w+PEz7MpltDpXYXltmN4l5J1ZGMXWsVGqzcNr9lBqLNC5AClXw+GnLxD237xHOGVAM6uIiQYiUh6JtIlgRIY/JCIQlv4Jdh+KqwhEVdzo9eA4DZycCmF3fwRhOPoAf0xHMlNAhMBJgrn1DnJ2AyoNmQzNqkHM2ggndaT1Mq51F9FsdTB1LoW94Tum8ADhhI5MrkxFOcSlsVrFKEPWXH6fyXnU7PJcMSq0gUU9GlKqi4Q5i4w9hzP+FHaHbyHsEvUsJWK2hAgBY7IJ1WlDztcgqQ4HZvMVqPkqz/VCHQmqYQqjkomoyCwqkMIkGIsDp2YStLLNgXE69eoitHIXqlXnMLa+6bT4v+XOQlTGK8cIGCPPWe/fQEpSSnEMpGma24FanOMAFsw3BmU2GMUm1Rb+AJrHAXVSWCSFV6B6XWTJSwZka+ZLLQ603IsTwAmFe68PMB0QIZM/cSpgYKM8D93rQLebHMZUMSBTyoCyVvoNYv5JagnTQRGMJezsjXDal6ACC77zEmbCCpJWG3G9PlYgsyE2QRz+AGndQzCq0EMm4bsgU4+MQETBiVN+vNoZQvj24xdu3l7BSn8Ld5cHuP9gA2uPX6L/6AX6m8/RX9/G2sYTDDafYnWwjfWtZ1he3cCdpYe4t7LOY4l6rt9awtfvPyHgP39Hux7MedR21AwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0df127e1dea92d94381bd5dfa4aae77a/e93cc/Night-Mode-Alt%402x.webp 300w", "/static/0df127e1dea92d94381bd5dfa4aae77a/b0544/Night-Mode-Alt%402x.webp 600w", "/static/0df127e1dea92d94381bd5dfa4aae77a/68fc1/Night-Mode-Alt%402x.webp 1200w", "/static/0df127e1dea92d94381bd5dfa4aae77a/a2303/Night-Mode-Alt%402x.webp 1800w", "/static/0df127e1dea92d94381bd5dfa4aae77a/4293a/Night-Mode-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0df127e1dea92d94381bd5dfa4aae77a/aaafa/Night-Mode-Alt%402x.png 300w", "/static/0df127e1dea92d94381bd5dfa4aae77a/8db2a/Night-Mode-Alt%402x.png 600w", "/static/0df127e1dea92d94381bd5dfa4aae77a/6ca50/Night-Mode-Alt%402x.png 1200w", "/static/0df127e1dea92d94381bd5dfa4aae77a/4d07a/Night-Mode-Alt%402x.png 1800w", "/static/0df127e1dea92d94381bd5dfa4aae77a/ee4de/Night-Mode-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0df127e1dea92d94381bd5dfa4aae77a/6ca50/Night-Mode-Alt%402x.png",
              "alt": "An example of how night mode could look.",
              "title": "Example of night mode that can inform theming strategy.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Example of night mode that can inform theming strategy.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Day/Night Mode`}</h2>
    <p>{`We decided to create “day” and “night” modes to kick the tires on a potential approach to white label theming. The main lift was to define semantic colors, which we could swap out to switch between modes.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACwklEQVQ4y3WT20sUURzH91WiPyECXzIkInUT0zKTaFfdeojUxAuJSYompBg9hNVDL0mREUK9RWAF2UWpkIou5ksEmVZmXlp3Z+87szO7s3Ndv/3OrJpbNvA5vx+/c85nZvidY4tJMubdAfzyhLDoCW6I2xuy+N8828sczGWT4go8vii4gADOz2dg1SnOecOYJ9bXMqC9rM5cNk0zKFERT/yNhqRiAgYwEYxgIhCxclb7d61qOZjLpqo6hFgSMfEPoqSAFxKY+voTX6Z+4NPkd3yensUk5dPf5mi9TOuUjD3MwVwkNNYk62G1QJCHn2AxGBLWclHaeD1z2QwzBYUSlT43HU0LTTdhmMtWPanokBXNyldrq7A9ykpkLkuo02YztUySFKK8gEhUSEdeRDgSQzDMI0SEIwLCUTYfI0RaI1ovJsXKB5DQTKUgyRoEUYYkifBSx9wcT0Th5fzgfAGEqCH+UBRLXBAeLkAdDVjR6wvSr8YJCXJShcmEoGf8zQuUHSjDnsPnUXpqBjXnZlHeMY+S6tsoKC7DzQePce/ufZTvd+JgVTVcRxotDlXWoLziGEodNXg29papqCnLNEbH0N+ejU32O6jv86L72gJaLi8hx/kamyvrcMvHYbD/BvK252FnQSn2lRxFUZEL9kIH8opdyN3twqORV2mhToPhGUVP7RZk7RqEo2sRzRdnUX/Bja1lI8gqceL6zAwGrgwgJzsXhfl7YbdXEk7YCxzYUViBbflVGH76Mi1kw/vxcRxvOIGmzqto6JvD6f4FNF1yo7F7CLV1TRh68hwPh0fR2tyGzq5enGzvIbrR0nYGrR29aG47i3cfPqaFKeoua4jXFwLP83TqE3QnExRlyHLCOiamkbI6HhEkxGXVaoBODWBzBs0xMrpsGKYFK6qaTldIt64RO2c61VfRdGNlPrPOhCwy12+458KazAGYyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/553119cf8f28894fcb9e0df40c781a27/e93cc/Heartwood-Tokens-Alt%402x.webp 300w", "/static/553119cf8f28894fcb9e0df40c781a27/b0544/Heartwood-Tokens-Alt%402x.webp 600w", "/static/553119cf8f28894fcb9e0df40c781a27/68fc1/Heartwood-Tokens-Alt%402x.webp 1200w", "/static/553119cf8f28894fcb9e0df40c781a27/a2303/Heartwood-Tokens-Alt%402x.webp 1800w", "/static/553119cf8f28894fcb9e0df40c781a27/4293a/Heartwood-Tokens-Alt%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/553119cf8f28894fcb9e0df40c781a27/aaafa/Heartwood-Tokens-Alt%402x.png 300w", "/static/553119cf8f28894fcb9e0df40c781a27/8db2a/Heartwood-Tokens-Alt%402x.png 600w", "/static/553119cf8f28894fcb9e0df40c781a27/6ca50/Heartwood-Tokens-Alt%402x.png 1200w", "/static/553119cf8f28894fcb9e0df40c781a27/4d07a/Heartwood-Tokens-Alt%402x.png 1800w", "/static/553119cf8f28894fcb9e0df40c781a27/ee4de/Heartwood-Tokens-Alt%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/553119cf8f28894fcb9e0df40c781a27/6ca50/Heartwood-Tokens-Alt%402x.png",
              "alt": "The Heartwood Tokens site.",
              "title": "Heartwood Tokens are available online.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Heartwood Tokens are available online.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Heartwood Tokens`}</h2>
    <p>{`Explorations in theming led to the development of Heartwood Tokens. We broke these out into a separate project and I created a static website to share with the team. Giving tokens their own home has the added benefit of making Heartwood more scalable. This opened the door to Heartwood evolving into a more robust set of tools with a cohesive foundation.`}</p>
    <CaseStudyConclusion headline="Results" text="Heartwood has already gone a long way to make Spruce useful, usable, and beautiful. By providing a shared language, it has equipped the team at Spruce Labs to continue evolving their core product. Heartwood will continue to play a role in defining the future of brick and mortar retail." mdxType="CaseStudyConclusion" />
    <NextCaseStudy slug="spruce-labs" image={{
      src: spruceLabsThumb,
      width: 736,
      height: 460
    }} title="Spruce Labs" description="Visual identity for the future of brick and mortar" tags='Visual Design, Design Strategy' mdxType="NextCaseStudy" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      